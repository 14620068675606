import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/SEO"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import TwoColumn from "../components/TwoColumn/TwoColumn"
import Hero from "../components/Hero/Hero"
import BGImg from "../components/LargeBGImg/LargeBGImg"
import ImageGrid from "../components/ImageGrid/ImageGrid"

const LosAngeles = ({ data }) => {

  // Grab variable from .md file
  const hero = data.hero.edges[0].node.frontmatter.heroImage.childImageSharp.gatsbyImageData;
  const heroAlt = data.hero.edges[0].node.frontmatter.heroImageAlt;
  const identifier = data.hero.edges[0].node.frontmatter.identifier;
  const heroDescription = data.hero.edges[0].node.frontmatter.description;

  // Large BG Images
  const losAngelesBGContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Los Angeles')
  const grandStatmentContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'A Grand Statement')
  const demNationalConventionContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.identifier === 'demNationalConvention')
  const WaltDisneyHallContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.identifier === 'WaltDisneyHall')
  const GrandAvenueContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.identifier === 'GrandAvenue')
  const theBroadContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.identifier === 'theBroad')
  const civicInitiativeLBGContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.identifier === 'civicInitiativeLBG')

  // Two Column Sections
  const twoColumnArtOfLife = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'artoflife')
  const twoColumnArtOfLifeTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'artoflifetop')
  const twoColumnBunkerHill = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'bunkerhill')
  const twoColumnBunkerHillTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'bunkerhillTop')
  const twoMocaOpening = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'mocaopening')
  const twoColumnDNCLosAngeles = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'dncLosAngeles')
  const twoColumnDNCLosAngelesTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'dncLosAngelesTop')
  const twoColumnNyNj = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'wiseofwaltimage')
  const twoColumnDelegates = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'delegates')
  const twoColumnhostCommittee = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'hostCommittee')
  const twoColumnJointPowers = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'jointpowers')
  const twoColumnJointPowersTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'jointpowerstop')
  const twoColumnGrandAvenueCommittee = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'grandavenuecommittee')
  const twoColumnGrandParkOpening = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'grandparkopening')
  const twoColumntheBroadTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'theBroadTop')
  const twoColumntheBroad = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'theBroad')
  const twoColumncivicInitiatives = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'civicInitiatives')



  // Image With Text
  const vanKempComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'vanKemp')
  const mocaHistoryComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'mocaHistory')
  const memorialColiseumComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'memorialColiseum')
  const thrilledComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'thrilled')
  const dncSingleimageComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'dcSingleImage')
  const elevateLAComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'elevateLA')
  const nynjComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'riseOfWaltDisney')
  const hurtingCityComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'hurtingCity')
  const grandParkVitalComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'grandParkVital')
  const grandParkFountanComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'grandParkFountain')
  const grandDifficultToOverstate = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'DifficultToOverstate')
  const broadVisitorsComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'broadVisitors')
  const dramaticLobbyComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'dramaticLobby')

  // Image Grid
  const imageGridJPAuthorityComponent = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'JPAuthority')
  const imageGridDNCBadgeComponent = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'dncBadge')
  const imageGridGrandParkComponent = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'GrandPark')
  const heartOfTheCity = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'heartofthecity')





  const positions = {
    rowOneWrapper: {
      classes: "row-wrapper one",
      TextRow1: {
        classes: "mb-200 center",
          text1: {
            value: "A",
            classes: "ten text-center"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
      TextRow2: {
        classes: "",
          text1: {
            value: "Champion",
            classes: "forty Rightauto"
          },
          text2: {
            value: "For",
            classes: "thirty text-left"
          }
      },
    },
    rowTwoWrapper: {
      classes: "row-wrapper two",
      TextRow1: {
        classes: "",
          text1: {
            value: "The",
            classes: "twenty Leftauto text-right"
          },
          text2: {
            value: " ",
            classes: "empty"
          }
      },
      TextRow2: {
        classes: "",
          text1: {
            value: "City",
            classes: "twenty"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
      TextRow3: {
        classes: "",
          text1: {
            value: "Of",
            classes: "twenty text-right"
          },
          text2: {
            value: "Angels",
            classes: "thirty Leftauto"
          }
      },
    }
  }
  return (
    <>
      <Seo title={"Eli Broad | Los Angeles"} description={"Always one to look ahead, Eli embraced the transformative opportunities Los Angeles could bring and launched Kaufman and Broad’s L.A. operations from a small office on Wilshire Boulevard near La Cienega Boulevard."} />

      <Hero heroimage={hero} heroimagealt={heroAlt} identifier={identifier} positions={positions} description={heroDescription} />

      <BGImg content={losAngelesBGContent} />

      <TwoColumn content={twoColumnArtOfLifeTop} type="top" />

      <TwoColumn content={twoColumnArtOfLife} type="bottom" />

      <ImageWithText content={memorialColiseumComponent} headerContent={true} />

      <BGImg content={grandStatmentContent} />

      <TwoColumn content={twoColumnBunkerHillTop} type="top"/>

      <TwoColumn content={twoColumnBunkerHill} type="bottom"/>

      <ImageWithText content={vanKempComponent} alignTop={true} imageFloat={true} />

      <ImageWithText content={mocaHistoryComponent} headerContent={true} />

      <TwoColumn content={twoMocaOpening}/>

      <BGImg content={demNationalConventionContent} />

      <TwoColumn content={twoColumnDNCLosAngelesTop} type="top"/>

      <TwoColumn content={twoColumnDNCLosAngeles} type="bottom"/>

      <ImageGrid content={imageGridDNCBadgeComponent} />

      <TwoColumn content={twoColumnhostCommittee}/>

      <ImageWithText content={thrilledComponent} headerContent={true} />

      <TwoColumn content={twoColumnDelegates}/>

      <ImageWithText content={dncSingleimageComponent} headerContent={true} />

      <ImageWithText content={elevateLAComponent} headerContent={true} />

      <BGImg content={WaltDisneyHallContent} />

      <TwoColumn content={twoColumnNyNj} noPadding={true} />

      <ImageWithText content={nynjComponent} alignTop={true} imageFloat={true} />

      <ImageWithText content={hurtingCityComponent} headerContent={true} />

      <BGImg content={GrandAvenueContent} />

      <TwoColumn content={twoColumnJointPowersTop} type="top" />

      <TwoColumn content={twoColumnJointPowers} type="bottom" />

      <ImageGrid content={imageGridJPAuthorityComponent} />

      <TwoColumn content={twoColumnGrandAvenueCommittee}/>

      <ImageGrid content={imageGridGrandParkComponent} />

      <TwoColumn content={twoColumnGrandParkOpening}/>

      <ImageWithText content={grandParkVitalComponent} headerContent={true} />

      <ImageWithText content={grandParkFountanComponent} />

      <BGImg content={theBroadContent} />

      <TwoColumn content={twoColumntheBroadTop} type="top"/>

      <TwoColumn content={twoColumntheBroad} type="bottom" />

      <ImageWithText content={broadVisitorsComponent} headerContent={true} />

      <ImageWithText content={dramaticLobbyComponent} headerContent={false} />

      <BGImg content={civicInitiativeLBGContent} />

      <TwoColumn content={twoColumncivicInitiatives}/>

      <ImageWithText content={grandDifficultToOverstate} headerContent={true} />

      <ImageGrid content={heartOfTheCity} />

      <div class="continue-link">
        <Link to="/politics">Continue to Political Engagement</Link>
      </div>

    </>
  )
}

export default LosAngeles

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {frontmatter: {identifier: {eq: "losAngelesHero"}}}) {
      edges {
        node {
          frontmatter {
            heroImage {
              childImageSharp {
                gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            heroImageAlt
            identifier
            intro
            title
            description
          }
        }
      }
    }
    imageWithText: allMarkdownRemark(filter: {frontmatter: {component: {eq: "imageWithText"}, page: {eq: "losangeles"}}}) {
      edges {
        node {
          ...imageWithTextQuery
        }
      }
    }
    largeBGImg: allMarkdownRemark(filter: {frontmatter: {component: {eq: "LargeBGImg"}, page: {eq: "losanngeles"}}}) {
      edges {
        node {
          ...largeBGImgQuery
        }
      }
    }
    twoColumn: allMarkdownRemark(filter: {frontmatter: {page: {eq: "losangeles"}, component: {eq: "twocolumn"}}}) {
      edges {
        node {
          ...twoColumnQuery
        }
      }
    }
    imageGrid: allMarkdownRemark(filter: {frontmatter: {page: {eq: "losangeles"}, component: {eq: "imageGrid"}}}) {
      edges {
        node {
          ...imageGridQuery
        }
      }
    }
  }
`
